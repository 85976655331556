import axios from 'axios'
import { config } from '../config';

export async function getVirtualMachines() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/virtualmachine').catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function startVirtualMachine(virtualMachineId) {
    console.log("Start Virtual Machine requested for virtualMachineId: " + virtualMachineId);
    await axios.post(config.OASIS_PORTAL_URL + 'api/virtualmachine/start/' + virtualMachineId).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
}

export async function restartVirtualMachine(restartInfo) {
    await axios.post(config.OASIS_PORTAL_URL + 'api/virtualmachine/restart/', restartInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
}

export async function stopVirtualMachine(stopInfo) {
    await axios.post(config.OASIS_PORTAL_URL + 'api/virtualmachine/stop/', stopInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
}